import React from "react";
import { Tooltip } from "react-tippy";
import styled from "styled-components";
import * as icons from "./icons";

export const templates = {
  default: {
    height: "1.5rem",
    width: "1.5rem",
    fill: "#DDE2E3"
  },
  recipe: {
    height: "1.25rem",
    width: "1.25rem",
    fill: "#977535"
  },
  openBook: {
    height: "1.35rem",
    width: "1.35rem",
    fill: "#DDE2E3"
  },
  x: {
    fill: "#b3b3b3",
    height: "1.25rem",
    width: "1.25rem"
  },
  groceryRecipeX: {
    fill: "#ccc",
    height: "1.5rem",
    width: "1.5rem"
  },
  gl: {
    fill: "#ccc",
    height: "1.6875rem",
    width: "1.6875rem"
  },
  mp: {
    fill: "#D6D1C2",
    height: "1.25rem",
    width: "1.25rem"
  },
  arrow: {
    fill: "#D6D1C2",
    height: "2rem",
    width: "2rem"
  }
};

const Center = styled("div")`
  display: flex;
  align-items: center;
`;

export type PossibleIcons = keyof typeof icons;

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  tooltipText?: string;
  tooltipPosition?: "bottom" | "top" | "right" | "left";
  name: PossibleIcons;
  path?: JSX.Element;
  template?: keyof typeof templates;
  style?: React.CSSProperties;
  svgRef?: any;
  arrow?: boolean;
  css?: string;
  tooltipTheme?: "light" | "dark";
}

export const Icon = ({
  tooltipText,
  tooltipPosition = "bottom",
  name,
  path,
  template = "default",
  style,
  svgRef,
  arrow = false,
  tooltipTheme = "light",
  ...other
}: IconProps) => {
  const iconComponent = (
    <Center>
      <svg
        data-testid={`icon-${name}`}
        viewBox={name === "openBook" ? "0 0 23 23" : "0 0 20 20"}
        {...templates[template]}
        {...other}
        ref={svgRef}
        style={{
          cursor: "pointer",
          ...style
        }}
      >
        {path ? path : <path d={icons[name]} />}
      </svg>
    </Center>
  );

  return tooltipText ? (
    <Tooltip
      arrow={arrow}
      title={tooltipText}
      position={tooltipPosition}
      theme={tooltipTheme}
      size="small"
    >
      {iconComponent}
    </Tooltip>
  ) : (
    iconComponent
  );
};

export default Icon;
