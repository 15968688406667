export const menu =
  "M16.4,9H3.6C3.048,9,3,9.447,3,10c0,0.553,0.048,1,0.6,1H16.4c0.552,0,0.6-0.447,0.6-1C17,9.447,16.952,9,16.4,9z M16.4,13H3.6C3.048,13,3,13.447,3,14c0,0.553,0.048,1,0.6,1H16.4c0.552,0,0.6-0.447,0.6-1C17,13.447,16.952,13,16.4,13z M3.6,7H16.4C16.952,7,17,6.553,17,6c0-0.553-0.048-1-0.6-1H3.6C3.048,5,3,5.447,3,6C3,6.553,3.048,7,3.6,7z";

export const search =
  "M17.545,15.467l-3.779-3.779c0.57-0.935,0.898-2.035,0.898-3.21c0-3.417-2.961-6.377-6.378-6.377C4.869,2.1,2.1,4.87,2.1,8.287c0,3.416,2.961,6.377,6.377,6.377c1.137,0,2.2-0.309,3.115-0.844l3.799,3.801c0.372,0.371,0.975,0.371,1.346,0l0.943-0.943C18.051,16.307,17.916,15.838,17.545,15.467z M4.004,8.287c0-2.366,1.917-4.283,4.282-4.283c2.366,0,4.474,2.107,4.474,4.474c0,2.365-1.918,4.283-4.283,4.283C6.111,12.76,4.004,10.652,4.004,8.287z";

export const circlePlus =
  "M10,1.6c-4.639,0-8.4,3.761-8.4,8.4c0,4.639,3.761,8.4,8.4,8.4s8.4-3.761,8.4-8.4C18.4,5.361,14.639,1.6,10,1.6z M15,11h-4v4H9v-4H5V9h4V5h2v4h4V11z";

export const pencil =
  "M14.69,2.661c-1.894-1.379-3.242-1.349-3.754-1.266c-0.144,0.023-0.265,0.106-0.35,0.223l-4.62,6.374l-2.263,3.123c-0.277,0.382-0.437,0.836-0.462,1.307l-0.296,5.624c-0.021,0.405,0.382,0.698,0.76,0.553l5.256-2.01c0.443-0.17,0.828-0.465,1.106-0.849l1.844-2.545l5.036-6.949c0.089-0.123,0.125-0.273,0.1-0.423C16.963,5.297,16.56,4.021,14.69,2.661z M8.977,15.465l-2.043,0.789c-0.08,0.031-0.169,0.006-0.221-0.062c-0.263-0.335-0.576-0.667-1.075-1.03c-0.499-0.362-0.911-0.558-1.31-0.706c-0.08-0.03-0.131-0.106-0.126-0.192l0.122-2.186l0.549-0.755c0,0,1.229-0.169,2.833,0.998c1.602,1.166,1.821,2.388,1.821,2.388L8.977,15.465z";

export const trash =
  "M3.389,7.113L4.49,18.021C4.551,18.482,6.777,19.998,10,20c3.225-0.002,5.451-1.518,5.511-1.979l1.102-10.908C14.929,8.055,12.412,8.5,10,8.5C7.59,8.5,5.072,8.055,3.389,7.113z M13.168,1.51l-0.859-0.951C11.977,0.086,11.617,0,10.916,0H9.085c-0.7,0-1.061,0.086-1.392,0.559L6.834,1.51C4.264,1.959,2.4,3.15,2.4,4.029v0.17C2.4,5.746,5.803,7,10,7c4.198,0,7.601-1.254,7.601-2.801v-0.17C17.601,3.15,15.738,1.959,13.168,1.51z M12.07,4.34L11,3H9L7.932,4.34h-1.7c0,0,1.862-2.221,2.111-2.522C8.533,1.588,8.727,1.5,8.979,1.5h2.043c0.253,0,0.447,0.088,0.637,0.318c0.248,0.301,2.111,2.522,2.111,2.522H12.07z";

export const picture =
  "M19,2H1C0.447,2,0,2.447,0,3v14c0,0.552,0.447,1,1,1h18c0.553,0,1-0.448,1-1V3C20,2.448,19.553,2,19,2z M18,16H2V4h16V16z M14.315,10.877l-3.231,1.605L7.314,6.381L4,14h12L14.315,10.877z M13.25,9c0.69,0,1.25-0.56,1.25-1.25S13.94,6.5,13.25,6.5S12,7.06,12,7.75S12.56,9,13.25,9z";

export const folder =
  "M18.405,4.799C18.294,4.359,17.75,4,17.195,4h-6.814C9.827,4,9.051,3.682,8.659,3.293L8.063,2.705C7.671,2.316,6.896,2,6.342,2H3.087C2.532,2,2.028,2.447,1.967,2.994L1.675,6h16.931L18.405,4.799zM19.412,7H0.588c-0.342,0-0.61,0.294-0.577,0.635l0.923,9.669C0.971,17.698,1.303,18,1.7,18H18.3c0.397,0,0.728-0.302,0.766-0.696l0.923-9.669C20.022,7.294,19.754,7,19.412,7z";

export const document =
  "M16,1H4C3.447,1,3,1.447,3,2v16c0,0.552,0.447,1,1,1h12c0.553,0,1-0.448,1-1V2C17,1.448,16.553,1,16,1z M13,15H7v-2h6V15z M13,11H7V9h6V11z M13,7H7V5h6V7z";

export const expand =
  "M6.987,10.987l-2.931,3.031L2,11.589V18h6.387l-2.43-2.081l3.03-2.932L6.987,10.987zM11.613,2l2.43,2.081l-3.03,2.932l2,2l2.931-3.031L18,8.411V2H11.613z";

export const shrink =
  "M4.1,14.1L1,17l2,2l2.9-3.1L8,18v-6H2L4.1,14.1zM19,3l-2-2l-2.9,3.1L12,2v6h6l-2.1-2.1L19,3z";

export const addToList =
  "M19.4,9H16V5.6C16,5,15.6,5,15,5s-1,0-1,0.6V9h-3.4C10,9,10,9.4,10,10s0,1,0.6,1H14v3.4c0,0.6,0.4,0.6,1,0.6s1,0,1-0.6V11h3.4c0.6,0,0.6-0.4,0.6-1S20,9,19.4,9zM7.4,9H0.6C0,9,0,9.4,0,10s0,1,0.6,1h6.8C8,11,8,10.6,8,10S8,9,7.4,9zM7.4,14H0.6C0,14,0,14.4,0,15s0,1,0.6,1h6.8C8,16,8,15.6,8,15S8,14,7.4,14zM7.4,4H0.6C0,4,0,4.4,0,5s0,1,0.6,1h6.8C8,6,8,5.6,8,5S8,4,7.4,4z";

export const groceryCart =
  "M13,17c0,1.104,0.894,2,2,2c1.104,0,2-0.896,2-2c0-1.106-0.896-2-2-2C13.894,15,13,15.894,13,17zM3,17c0,1.104,0.895,2,2,2c1.103,0,2-0.896,2-2c0-1.106-0.897-2-2-2C3.895,15,3,15.894,3,17zM6.547,12.172L17.615,9.01C17.826,8.949,18,8.721,18,8.5V3H4V1.4C4,1.18,3.819,1,3.601,1H0.399C0.18,1,0,1.18,0,1.4L0,3h2l1.91,8.957L4,12.9v1.649c0,0.219,0.18,0.4,0.4,0.4H17.6c0.22,0,0.4-0.182,0.4-0.4V13H6.752C5.602,13,5.578,12.449,6.547,12.172z";

export const book =
  "M17,5.95v10.351c0,0.522-0.452,0.771-1,1.16c-0.44,0.313-1-0.075-1-0.587c0,0,0-9.905,0-10.114c0-0.211-0.074-0.412-0.314-0.535c-0.24-0.123-7.738-4.065-7.738-4.065C6.827,2.115,6.299,1.782,5.595,2.144C4.926,2.488,4.562,2.862,4.469,3.038l8.18,4.482C12.866,7.634,13,7.81,13,8.036v10.802c0,0.23-0.142,0.476-0.369,0.585c-0.104,0.052-0.219,0.077-0.333,0.077c-0.135,0-0.271-0.033-0.386-0.104c-0.215-0.131-7.774-4.766-8.273-5.067c-0.24-0.144-0.521-0.439-0.527-0.658L3,3.385c0-0.198-0.023-0.547,0.289-1.032c0.697-1.084,3.129-2.317,4.36-1.678l8.999,4.555C16.865,5.342,17,5.566,17,5.95z";

export const openBook =
  "M19,2L14,6.5V17.5L19,13V2M6.5,5C4.55,5 2.45,5.4 1,6.5V21.16C1,21.41 1.25,21.66 1.5,21.66C1.6,21.66 1.65,21.59 1.75,21.59C3.1,20.94 5.05,20.5 6.5,20.5C8.45,20.5 10.55,20.9 12,22C13.35,21.15 15.8,20.5 17.5,20.5C19.15,20.5 20.85,20.81 22.25,21.56C22.35,21.61 22.4,21.59 22.5,21.59C22.75,21.59 23,21.34 23,21.09V6.5C22.4,6.05 21.75,5.75 21,5.5V7.5L21,13V19C19.9,18.65 18.7,18.5 17.5,18.5C15.8,18.5 13.35,19.15 12,20V13L12,8.5V6.5C10.55,5.4 8.45,5 6.5,5V5Z";

export const link =
  "M7.859,14.691l-0.81,0.805c-0.701,0.695-1.843,0.695-2.545,0c-0.336-0.334-0.521-0.779-0.521-1.252c0-0.473,0.186-0.916,0.521-1.252l2.98-2.955c0.617-0.613,1.779-1.515,2.626-0.675c0.389,0.386,1.016,0.384,1.403-0.005c0.385-0.389,0.383-1.017-0.006-1.402C10.069,6.527,7.941,6.791,6.088,8.63l-2.98,2.956C2.393,12.295,2,13.24,2,14.244c0,1.006,0.394,1.949,1.108,2.658c0.736,0.73,1.702,1.096,2.669,1.096c0.967,0,1.934-0.365,2.669-1.096l0.811-0.805c0.389-0.385,0.391-1.012,0.005-1.4C8.875,14.309,8.248,14.307,7.859,14.691zM16.891,3.207c-1.547-1.534-3.709-1.617-5.139-0.197l-1.009,1.002c-0.389,0.386-0.392,1.013-0.006,1.401c0.386,0.389,1.013,0.391,1.402,0.005l1.01-1.001c0.74-0.736,1.711-0.431,2.346,0.197c0.336,0.335,0.522,0.779,0.522,1.252s-0.186,0.917-0.522,1.251l-3.18,3.154c-1.454,1.441-2.136,0.766-2.427,0.477c-0.389-0.386-1.016-0.383-1.401,0.005c-0.386,0.389-0.384,1.017,0.005,1.401c0.668,0.662,1.43,0.99,2.228,0.99c0.977,0,2.01-0.492,2.993-1.467l3.18-3.153C17.605,7.814,18,6.87,18,5.866C18,4.861,17.605,3.917,16.891,3.207z";

export const circleCancel =
  "M10,1.6c-4.639,0-8.4,3.761-8.4,8.4c0,4.639,3.761,8.4,8.4,8.4s8.4-3.761,8.4-8.4C18.4,5.361,14.639,1.6,10,1.6zM14.789,13.061l-1.729,1.729L10,11.729l-3.061,3.06l-1.729-1.729L8.272,10L5.211,6.939L6.94,5.211L10,8.271l3.061-3.061l1.729,1.729L11.728,10L14.789,13.061z";

export const leftArrow =
  "M12.452,4.516c0.446,0.436,0.481,1.043,0,1.576L8.705,10l3.747,3.908c0.481,0.533,0.446,1.141,0,1.574c-0.445,0.436-1.197,0.408-1.615,0c-0.418-0.406-4.502-4.695-4.502-4.695C6.112,10.57,6,10.285,6,10s0.112-0.57,0.335-0.789c0,0,4.084-4.287,4.502-4.695C11.255,4.107,12.007,4.08,12.452,4.516z";

export const rightArrow =
  "M9.163,4.516c0.418,0.408,4.502,4.695,4.502,4.695C13.888,9.43,14,9.715,14,10s-0.112,0.57-0.335,0.787c0,0-4.084,4.289-4.502,4.695c-0.418,0.408-1.17,0.436-1.615,0c-0.446-0.434-0.481-1.041,0-1.574L11.295,10L7.548,6.092c-0.481-0.533-0.446-1.141,0-1.576C7.993,4.08,8.745,4.107,9.163,4.516z";

export const circleMinus =
  "M10,1.6c-4.639,0-8.4,3.761-8.4,8.4c0,4.639,3.761,8.4,8.4,8.4s8.4-3.761,8.4-8.4C18.4,5.361,14.639,1.6,10,1.6zM15,11H5V9h10V11z";

export const x =
  "M14.348,14.849c-0.469,0.469-1.229,0.469-1.697,0L10,11.819l-2.651,3.029c-0.469,0.469-1.229,0.469-1.697,0c-0.469-0.469-0.469-1.229,0-1.697l2.758-3.15L5.651,6.849c-0.469-0.469-0.469-1.228,0-1.697c0.469-0.469,1.228-0.469,1.697,0L10,8.183l2.651-3.031c0.469-0.469,1.228-0.469,1.697,0c0.469,0.469,0.469,1.229,0,1.697l-2.758,3.152l2.758,3.15C14.817,13.62,14.817,14.38,14.348,14.849z";

export const circle =
  "M10,3c-3.866,0-7,3.133-7,7c0,3.865,3.134,7,7,7s7-3.135,7-7C17,6.133,13.866,3,10,3z";

export const plus =
  "M16,10c0,0.553-0.048,1-0.601,1H11v4.399C11,15.951,10.553,16,10,16c-0.553,0-1-0.049-1-0.601V11H4.601C4.049,11,4,10.553,4,10c0-0.553,0.049-1,0.601-1H9V4.601C9,4.048,9.447,4,10,4c0.553,0,1,0.048,1,0.601V9h4.399C15.952,9,16,9.447,16,10z";

export const downArrow =
  "M13.418,7.859c0.271-0.268,0.709-0.268,0.978,0c0.27,0.268,0.272,0.701,0,0.969l-3.908,3.83c-0.27,0.268-0.707,0.268-0.979,0l-3.908-3.83c-0.27-0.267-0.27-0.701,0-0.969c0.271-0.268,0.709-0.268,0.978,0L10,11L13.418,7.859z";

export const upArrow =
  "M6.582,12.141c-0.271,0.268-0.709,0.268-0.978,0c-0.269-0.268-0.272-0.701,0-0.969l3.908-3.83c0.27-0.268,0.707-0.268,0.979,0l3.908,3.83c0.27,0.267,0.27,0.701,0,0.969c-0.271,0.268-0.709,0.268-0.979,0L10,9L6.582,12.141z";

export const smallRightArrow =
  "M11,10L7.859,6.58c-0.268-0.27-0.268-0.707,0-0.978c0.268-0.27,0.701-0.27,0.969,0l3.83,3.908c0.268,0.271,0.268,0.709,0,0.979l-3.83,3.908c-0.267,0.272-0.701,0.27-0.969,0c-0.268-0.269-0.268-0.707,0-0.978L11,10z";

export const questionMark =
  "M10,0.4c-5.302,0-9.6,4.298-9.6,9.6s4.298,9.6,9.6,9.6c5.301,0,9.6-4.298,9.6-9.601C19.6,4.698,15.301,0.4,10,0.4zM9.849,15.599H9.798c-0.782-0.023-1.334-0.6-1.311-1.371c0.022-0.758,0.587-1.309,1.343-1.309l0.046,0.002c0.804,0.023,1.35,0.594,1.327,1.387C11.18,15.068,10.625,15.599,9.849,15.599zM13.14,9.068c-0.184,0.26-0.588,0.586-1.098,0.983l-0.562,0.387c-0.308,0.24-0.494,0.467-0.563,0.688c-0.056,0.174-0.082,0.221-0.087,0.576v0.09H8.685l0.006-0.182c0.027-0.744,0.045-1.184,0.354-1.547c0.485-0.568,1.555-1.258,1.6-1.287c0.154-0.115,0.283-0.246,0.379-0.387c0.225-0.311,0.324-0.555,0.324-0.793c0-0.334-0.098-0.643-0.293-0.916c-0.188-0.266-0.545-0.398-1.061-0.398c-0.512,0-0.863,0.162-1.072,0.496c-0.216,0.341-0.325,0.7-0.325,1.067v0.092H6.386L6.39,7.841c0.057-1.353,0.541-2.328,1.435-2.897C8.388,4.583,9.089,4.4,9.906,4.4c1.068,0,1.972,0.26,2.682,0.772c0.721,0.519,1.086,1.297,1.086,2.311C13.673,8.05,13.494,8.583,13.14,9.068z";

export const sadFace =
  "M10.001,0.4C4.698,0.4,0.4,4.698,0.4,10c0,5.303,4.298,9.601,9.601,9.601c5.301,0,9.6-4.298,9.6-9.601C19.6,4.698,15.301,0.4,10.001,0.4zM10,17.599c-4.197,0-7.6-3.402-7.6-7.6c0-4.197,3.402-7.6,7.6-7.6c4.197,0,7.6,3.402,7.6,7.6C17.6,14.197,14.197,17.599,10,17.599zM12.501,9.75c0.828,0,1.5-0.783,1.5-1.75s-0.672-1.75-1.5-1.75c-0.828,0-1.5,0.783-1.5,1.75S11.672,9.75,12.501,9.75zM7.501,9.75c0.828,0,1.5-0.783,1.5-1.75s-0.672-1.75-1.5-1.75c-0.828,0-1.5,0.783-1.5,1.75S6.672,9.75,7.501,9.75zM10.002,11.25c-3.424,0-4.622,2.315-4.672,2.414c-0.186,0.371-0.035,0.821,0.335,1.007C5.773,14.724,5.887,14.75,6,14.75c0.275,0,0.54-0.151,0.672-0.414c0.008-0.017,0.822-1.586,3.33-1.586c2.463,0,3.298,1.527,3.328,1.585c0.184,0.37,0.635,0.523,1.006,0.336c0.371-0.184,0.521-0.636,0.336-1.006C14.623,13.566,13.426,11.25,10.002,11.25z";

export const share =
  "M15,13.442c-0.633,0-1.204,0.246-1.637,0.642l-5.938-3.463C7.471,10.433,7.5,10.237,7.5,10.037S7.471,9.642,7.425,9.454L13.3,6.025C13.746,6.442,14.342,6.7,15,6.7c1.379,0,2.5-1.121,2.5-2.5S16.379,1.7,15,1.7s-2.5,1.121-2.5,2.5c0,0.2,0.029,0.396,0.075,0.583L6.7,8.212C6.254,7.796,5.658,7.537,5,7.537c-1.379,0-2.5,1.121-2.5,2.5s1.121,2.5,2.5,2.5c0.658,0,1.254-0.258,1.7-0.675l5.938,3.463c-0.042,0.175-0.067,0.358-0.067,0.546c0,1.342,1.087,2.429,2.429,2.429s2.429-1.088,2.429-2.429S16.342,13.442,15,13.442z";

export const clipboard =
  "M15.6,2l-1.2,3H5.6l-1.2-3C3.629,2,3,2.629,3,3.4V18.6c0,0.77,0.629,1.4,1.399,1.4h11.2c0.77,0,1.4-0.631,1.4-1.4V3.4C17,2.629,16.369,2,15.6,2zM13.6,4l0.9-2h-2.181L11.6,0h-3.2L7.68,2H5.5l0.899,2H13.6z";

export const check =
  "M8.294,16.998c-0.435,0-0.847-0.203-1.111-0.553L3.61,11.724c-0.465-0.613-0.344-1.486,0.27-1.951c0.615-0.467,1.488-0.344,1.953,0.27l2.351,3.104l5.911-9.492c0.407-0.652,1.267-0.852,1.921-0.445c0.653,0.406,0.854,1.266,0.446,1.92L9.478,16.34c-0.242,0.391-0.661,0.635-1.12,0.656C8.336,16.998,8.316,16.998,8.294,16.998z";

export const logout =
  "M19,10l-6-5v3H6v4h7v3L19,10z M3,3h8V1H3C1.9,1,1,1.9,1,3v14c0,1.1,0.9,2,2,2h8v-2H3V3z";

export const thinLeftArrow =
  "M13.891,17.418c0.268,0.272,0.268,0.709,0,0.979c-0.268,0.27-0.701,0.271-0.969,0l-7.83-7.908c-0.268-0.27-0.268-0.707,0-0.979l7.83-7.908c0.268-0.27,0.701-0.27,0.969,0c0.268,0.271,0.268,0.709,0,0.979L6.75,10L13.891,17.418z";

export const thinRightArrow =
  "M13.25,10L6.109,2.58c-0.268-0.27-0.268-0.707,0-0.979c0.268-0.27,0.701-0.27,0.969,0l7.83,7.908c0.268,0.271,0.268,0.709,0,0.979l-7.83,7.908c-0.268,0.271-0.701,0.27-0.969,0c-0.268-0.269-0.268-0.707,0-0.979L13.25,10z";

export const cog =
  "M16.783,10c0-1.049,0.646-1.875,1.617-2.443c-0.176-0.584-0.407-1.145-0.692-1.672c-1.089,0.285-1.97-0.141-2.711-0.883c-0.741-0.74-0.968-1.621-0.683-2.711c-0.527-0.285-1.088-0.518-1.672-0.691C12.074,2.57,11.047,3.215,10,3.215c-1.048,0-2.074-0.645-2.643-1.615C6.772,1.773,6.213,2.006,5.686,2.291c0.285,1.09,0.059,1.971-0.684,2.711C4.262,5.744,3.381,6.17,2.291,5.885C2.006,6.412,1.774,6.973,1.6,7.557C2.57,8.125,3.215,8.951,3.215,10c0,1.047-0.645,2.074-1.615,2.643c0.175,0.584,0.406,1.144,0.691,1.672c1.09-0.285,1.971-0.059,2.711,0.682c0.741,0.742,0.969,1.623,0.684,2.711c0.527,0.285,1.087,0.518,1.672,0.693c0.568-0.973,1.595-1.617,2.643-1.617c1.047,0,2.074,0.645,2.643,1.617c0.584-0.176,1.144-0.408,1.672-0.693c-0.285-1.088-0.059-1.969,0.683-2.711c0.741-0.74,1.622-1.166,2.711-0.883c0.285-0.527,0.517-1.086,0.692-1.672C17.429,11.873,16.783,11.047,16.783,10zM10,13.652c-2.018,0-3.653-1.635-3.653-3.652c0-2.018,1.636-3.654,3.653-3.654c2.018,0,3.652,1.637,3.652,3.654C13.652,12.018,12.018,13.652,10,13.652z";

export const calendarAdd =
  "M12.1 12c0 .2 0 .6-.4.6H9.6v2.1c0 .4-.4.4-.6.4s-.6 0-.6-.4v-2.1H6.3c-.4 0-.4-.4-.4-.6s0-.6.4-.6h2.1V9.3c0-.4.4-.4.6-.4s.6 0 .6.4v2.1h2.1c.4 0 .4.4.4.6zM18 4v12c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h1v2h3V2h6v2h3V2h1c1.1 0 2 .9 2 2zm-2 4H2v8h14V8zM5.5 3.5h-2V0h2v3.5zm9 0h-2V0h2v3.5z";

export const calendarDate =
  "M16 2h-1v2h-3V2H6v2H3V2H2C.9 2 0 2.9 0 4v12c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H2V8h14v8zM5.5 0h-2v3.5h2V0zm9 0h-2v3.5h2V0zM5.1 10.5c.1-.7.6-1.6 2-1.6 1.3 0 1.9.8 1.9 1.6 0 .7-.4 1.1-.8 1.2.4.2 1 .6 1 1.5s-.6 1.9-2.2 1.9c-1.5 0-2.1-.9-2.2-1.7h1.1c.1.5.4.8 1.1.8.7 0 1-.5 1-1 0-.6-.4-1-1.1-1h-.3v-.9H7c.7 0 .9-.2.9-.8.1-.5-.2-.8-.8-.8-.7 0-.8.4-.9.7H5.1zm6.1 4.5v-4.7c-.3.2-.8.3-1.1.4v-.9c.5-.1 1.1-.4 1.5-.8h.8v6h-1.2z";

export const eye =
  "M10,4.4C3.439,4.4,0,9.232,0,10c0,0.766,3.439,5.6,10,5.6c6.56,0,10-4.834,10-5.6C20,9.232,16.56,4.4,10,4.4zM10,14.307c-2.455,0-4.445-1.928-4.445-4.307c0-2.379,1.99-4.309,4.445-4.309c2.455,0,4.444,1.93,4.444,4.309C14.444,12.379,12.455,14.307,10,14.307zM10,10c-0.407-0.447,0.663-2.154,0-2.154c-1.228,0-2.223,0.965-2.223,2.154c0,1.189,0.995,2.154,2.223,2.154c1.227,0,2.223-0.965,2.223-2.154C12.223,9.453,10.346,10.379,10,10z";

export const arrowCookbook =
  "M16.6 4.8L7.6.2C6.4-.5 3.9.8 3.2 1.9c-.3.5-.2.8-.2 1l.1 10.5c0 .2.3.5.5.7.5.3 8.1 5 8.3 5.2.1.1.3.1.4.1.1 0 .2 0 .3-.1.2-.1.4-.3.4-.6v-11c0-.2-.1-.4-.4-.6L4.4 2.6c.1-.2.5-.6 1.1-.9.7-.4 1.2-.2 1.4-.2 0 0 7.8 4.2 8.1 4.3.2.1.2.1.2.4v10.5c0 .5.5.7.9.7s.8-.4.8-.7V5.4c.1-.3-.1-.5-.3-.6zM5.4 8.3L8 9.8v-1l1.4 2.7L8 13.1V12l-2.7-1.7v-2z";

export const move =
  "M3 2c-.5523 0-1-.4477-1-1s.4477-1 1-1 1 .4477 1 1-.4477 1-1 1zm0 4c-.5523 0-1-.4477-1-1s.4477-1 1-1 1 .4477 1 1-.4477 1-1 1zm0 4c-.5523 0-1-.4477-1-1s.4477-1 1-1 1 .4477 1 1-.4477 1-1 1zm4-8c-.5523 0-1-.4477-1-1s.4477-1 1-1 1 .4477 1 1-.4477 1-1 1zm0 4c-.5523 0-1-.4477-1-1s.4477-1 1-1 1 .4477 1 1-.4477 1-1 1zm0 4c-.5523 0-1-.4477-1-1s.4477-1 1-1 1 .4477 1 1-.4477 1-1 1z";

export const thinDownArrow =
  "M17.418,6.109c0.272-0.268,0.709-0.268,0.979,0c0.27,0.268,0.271,0.701,0,0.969l-7.908,7.83 c-0.27,0.268-0.707,0.268-0.979,0l-7.908-7.83c-0.27-0.268-0.27-0.701,0-0.969c0.271-0.268,0.709-0.268,0.979,0L10,13.25 L17.418,6.109z";

export const thinUpArrow =
  "M2.582,13.891c-0.272,0.268-0.709,0.268-0.979,0s-0.271-0.701,0-0.969l7.908-7.83c0.27-0.268,0.707-0.268,0.979,0	l7.908,7.83c0.27,0.268,0.27,0.701,0,0.969c-0.271,0.268-0.709,0.268-0.978,0L10,6.75L2.582,13.891z";

export const archive =
  "M13.981,2H6.018c0,0-0.996,0-0.996,1h9.955C14.977,2,13.981,2,13.981,2z M16.968,5c0-1-0.995-1-0.995-1H4.027 c0,0-0.995,0-0.995,1v1h13.936V5z M18.958,6C18.37,5.408,18.37,5.408,18.37,5.408V7H1.63V5.408c0,0,0,0-0.589,0.592 C0.452,6.592,0.03,6.75,0.267,8c0.236,1.246,1.379,8.076,1.549,9c0.186,1.014,1.217,1,1.217,1h13.936c0,0,1.03,0.014,1.217-1 c0.17-0.924,1.312-7.754,1.549-9C19.97,6.75,19.548,6.592,18.958,6z M14,11.997C14,12.551,13.551,13,12.997,13H7.003 C6.449,13,6,12.551,6,11.997V10h1v2h6v-2h1V11.997z";

export const squarePlus =
  "M16,2H4C2.9,2,2,2.9,2,4v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C18,2.9,17.1,2,16,2z M15,11h-4v4H9v-4H5V9h4V5h2v4h4V11z";

export const exportPdf =
  "M15,15H2V6h2.595c0,0,0.689-0.896,2.17-2H1C0.447,4,0,4.449,0,5v11c0,0.553,0.447,1,1,1h15c0.553,0,1-0.447,1-1v-3.746 l-2,1.645V15z M13.361,8.05v3.551L20,6.4l-6.639-4.999v3.131C5.3,4.532,5.3,12.5,5.3,12.5C7.582,8.752,8.986,8.05,13.361,8.05z";

export const bowl =
  "M16.949,7.472c-2.176,2.902-4.095,3.002-7.046,3.152H9.802c-3.591-0.002-6.138-1.336-6.138-1.832 C3.662,8.321,5.962,7.095,9.269,6.973l0.59-1.473c-0.019,0-0.037-0.002-0.057-0.002c-4.908,0-7.791,1.562-7.791,3.051v2 c0,0.918,0.582,8.949,7.582,8.949s8-8.031,8-8.949v-2c0-0.391-0.201-0.787-0.584-1.158L16.949,7.472z M17.589,2.702 c-0.441-0.33-1.069-0.242-1.399,0.201l-3.608,4.809l2.336-5.838c0.206-0.512-0.044-1.094-0.557-1.301 c-0.508-0.205-1.096,0.043-1.3,0.559L9.802,9.274c2.882-0.147,4.277-0.227,6.067-2.611c1.789-2.387,1.919-2.561,1.919-2.561 C18.12,3.661,18.031,3.034,17.589,2.702z";

export const dots =
  "M10.001,7.8C8.786,7.8,7.8,8.785,7.8,10s0.986,2.2,2.201,2.2c1.215,0,2.199-0.985,2.199-2.2S11.216,7.8,10.001,7.8z M10.001,5.2C11.216,5.2,12.2,4.214,12.2,3S11.216,0.8,10.001,0.8C8.786,0.8,7.8,1.785,7.8,3S8.786,5.2,10.001,5.2z M10.001,14.8 C8.786,14.8,7.8,15.785,7.8,17s0.986,2.2,2.201,2.2c1.215,0,2.199-0.985,2.199-2.2S11.216,14.8,10.001,14.8z";

export const airplane =
  "M18.64,2.634C18.296,2.755,1.319,8.738,0.984,8.856c-0.284,0.1-0.347,0.345-0.01,0.479c0.401,0.161,3.796,1.521,3.796,1.521 l0,0l2.25,0.901c0,0,10.838-7.958,10.984-8.066c0.148-0.108,0.318,0.095,0.211,0.211c-0.107,0.117-7.871,8.513-7.871,8.513v0.002 L9.892,12.92l0.599,0.322l0,0c0,0,4.65,2.504,4.982,2.682c0.291,0.156,0.668,0.027,0.752-0.334 c0.099-0.426,2.845-12.261,2.906-12.525C19.21,2.722,18.983,2.513,18.64,2.634z M7,17.162c0,0.246,0.139,0.315,0.331,0.141 c0.251-0.229,2.85-2.561,2.85-2.561L7,13.098V17.162z";

export const message =
  "M18,6v7c0,1.1-0.9,2-2,2h-4v3l-4-3H4c-1.101,0-2-0.9-2-2V6c0-1.1,0.899-2,2-2h12C17.1,4,18,4.9,18,6z";

export const ruler =
  "M14.249,0.438L0.438,14.251c-0.584,0.584-0.584,1.538,0.002,2.124l3.185,3.187c0.584,0.584,1.541,0.586,2.124,0.002 L19.562,5.751c0.584-0.585,0.584-1.541,0-2.125l-3.186-3.188C15.789-0.148,14.834-0.145,14.249,0.438z M3.929,15.312L3.17,16.071 l-1.896-1.897l0.759-0.759L3.929,15.312z M6.965,15.312l-0.759,0.759l-3.415-3.415l0.759-0.76L6.965,15.312z M6.965,12.276 l-0.759,0.759l-1.898-1.896l0.76-0.76L6.965,12.276z M8.483,10.758l-0.759,0.759L5.828,9.621l0.759-0.76L8.483,10.758z  M11.518,10.758l-0.759,0.759L7.345,8.103l0.759-0.759L11.518,10.758z M11.518,7.723l-0.759,0.759L8.863,6.586l0.759-0.759 L11.518,7.723z M13.036,6.206l-0.759,0.759L10.38,5.068l0.759-0.759L13.036,6.206z M16.072,6.206l-0.76,0.759L11.898,3.55 l0.759-0.76L16.072,6.206z M16.071,3.171l-0.759,0.759l-1.896-1.898l0.759-0.758L16.071,3.171z";

export const triangleUp = "M15,14H5l5-9L15,14z";

export const triangleDown = "M5,6h10l-5,9L5,6z";

export const calendar =
  "M17,3h-1v2h-3V3H7v2H4V3H3C1.899,3,1,3.9,1,5v12c0,1.1,0.899,2,2,2h14c1.1,0,2-0.9,2-2V5C19,3.9,18.1,3,17,3z M17,17H3V9h14 V17z M6.5,1h-2v3.5h2V1z M15.5,1h-2v3.5h2V1z";

export const apple = `
M17.5640259,13.8623047
	c-0.4133301,0.9155273-0.6115723,1.3251343-1.1437988,2.1346436c-0.7424927,1.1303711-1.7894897,2.5380249-3.086853,2.5500488
	c-1.1524048,0.0109253-1.4483032-0.749939-3.0129395-0.741333c-1.5640259,0.008606-1.8909302,0.755127-3.0438843,0.7442017
	c-1.296814-0.0120239-2.2891235-1.2833252-3.0321655-2.4136963c-2.0770874-3.1607666-2.2941895-6.8709106-1.0131836-8.8428955
	c0.9106445-1.4013062,2.3466187-2.2217407,3.6970215-2.2217407c1.375,0,2.239502,0.7539673,3.3761597,0.7539673
	c1.1028442,0,1.7749023-0.755127,3.3641357-0.755127c1.201416,0,2.4744263,0.6542969,3.3816528,1.7846069
	C14.0778809,8.4837646,14.5608521,12.7279663,17.5640259,13.8623047z M12.4625244,3.8076782
	c0.5775146-0.741333,1.0163574-1.7880859,0.8571167-2.857666c-0.9436035,0.0653076-2.0470581,0.6651611-2.6912842,1.4477539
  C10.0437012,3.107605,9.56073,4.1605835,9.7486572,5.1849365C10.7787476,5.2164917,11.8443604,4.6011963,12.4625244,3.8076782z`;

export const playstore = `
M4.942627,18.0508423l7.6660156-4.3273926l-1.6452026-1.8234253L4.942627,18.0508423z M2.1422119,2.1231079
	C2.0543823,2.281311,2,2.4631958,2,2.664917v15.1259766c0,0.2799683,0.1046143,0.5202026,0.2631226,0.710144l7.6265259-7.7912598
	L2.1422119,2.1231079z M17.4795532,9.4819336l-2.6724854-1.508606l-2.72229,2.7811279l1.9516602,2.1630249l3.4431152-1.9436035
	C17.7927856,10.8155518,17.9656372,10.5287476,18,10.2279053C17.9656372,9.927063,17.7927856,9.6402588,17.4795532,9.4819336z
	 M13.3649292,7.1592407L4.1452026,1.954834l6.8656616,7.609314L13.3649292,7.1592407z
`;

export const smiley = `M10,0.4C4.698,0.4,0.4,4.698,0.4,10C0.4,15.302,4.698,19.6,10,19.6c5.301,0,9.6-4.298,9.6-9.601
C19.6,4.698,15.301,0.4,10,0.4z M10,17.599c-4.197,0-7.6-3.402-7.6-7.6S5.802,2.4,10,2.4c4.197,0,7.601,3.402,7.601,7.6
S14.197,17.599,10,17.599z M7.501,9.75C8.329,9.75,9,8.967,9,8s-0.672-1.75-1.5-1.75s-1.5,0.783-1.5,1.75S6.672,9.75,7.501,9.75z
 M12.5,9.75C13.329,9.75,14,8.967,14,8s-0.672-1.75-1.5-1.75S11,7.034,11,8S11.672,9.75,12.5,9.75z M14.341,11.336
c-0.363-0.186-0.815-0.043-1.008,0.32c-0.034,0.066-0.869,1.593-3.332,1.593c-2.451,0-3.291-1.513-3.333-1.592
c-0.188-0.365-0.632-0.514-1.004-0.329c-0.37,0.186-0.52,0.636-0.335,1.007c0.05,0.099,1.248,2.414,4.672,2.414
c3.425,0,4.621-2.316,4.67-2.415C14.855,11.967,14.707,11.524,14.341,11.336z`;

export const rotate = `
M19.315,10h-2.372V9.795c-0.108-4.434-3.724-7.996-8.169-7.996C4.259,1.799,0.6,5.471,0.6,10s3.659,8.199,8.174,8.199
	c1.898,0,3.645-0.65,5.033-1.738l-1.406-1.504c-1.016,0.748-2.27,1.193-3.627,1.193c-3.386,0-6.131-2.754-6.131-6.15
	s2.745-6.15,6.131-6.15c3.317,0,6.018,2.643,6.125,5.945V10h-2.672l3.494,3.894L19.315,10z
`;

export const magnifierPlus = `
M17.5,15.5l-3.8-3.8c0.6-0.9,0.9-2,0.9-3.2c0-3.4-3-6.4-6.4-6.4C4.8,2.1,2,4.9,2,8.3
	c0,3.4,3,6.4,6.4,6.4c1.1,0,2.2-0.3,3.1-0.8l3.8,3.8c0.4,0.4,1,0.4,1.3,0l0.9-0.9C18.1,16.3,17.9,15.8,17.5,15.5z M8.5,12.8
	C6.1,12.8,4,10.7,4,8.3C4,5.9,5.9,4,8.3,4c2.4,0,4.5,2.1,4.5,4.5S10.9,12.8,8.5,12.8z M11.3,7.8c0.3,0,0.3,0.2,0.3,0.6
	c0,0.3,0,0.6-0.3,0.6H9v2.3c0,0.3-0.2,0.3-0.6,0.3c-0.3,0-0.6,0-0.6-0.3V9H5.5C5.2,9,5.2,8.7,5.2,8.4c0-0.3,0-0.6,0.3-0.6h2.3V5.5
  c0-0.3,0.2-0.3,0.6-0.3c0.3,0,0.6,0,0.6,0.3v2.3H11.3z`;

export const magnifierMinus = `
  M17.5,15.5l-3.8-3.8c0.6-0.9,0.9-2,0.9-3.2c0-3.4-3-6.4-6.4-6.4c-3.4,0-6.2,2.8-6.2,6.2c0,3.4,3,6.4,6.4,6.4
	c1.1,0,2.2-0.3,3.1-0.8l3.8,3.8c0.4,0.4,1,0.4,1.3,0l0.9-0.9C18.1,16.3,17.9,15.8,17.5,15.5z M4,8.3C4,5.9,5.9,4,8.3,4
	c2.4,0,4.5,2.1,4.5,4.5c0,2.4-1.9,4.3-4.3,4.3C6.1,12.8,4,10.7,4,8.3z M11.4,7.8l0,1.4h-6V7.8L11.4,7.8z`;

export const groceryBasket = `
  M18.399,7h-5.007l-1.812,1.812c-0.453,0.453-1.056,0.702-1.696,0.702c-0.642,0-1.244-0.25-1.698-0.703
	C7.734,8.359,7.484,7.757,7.483,7.116c0-0.039,0.01-0.077,0.011-0.116H1.6C1.268,7,1,7.268,1,7.6V10h18V7.6
	C19,7.268,18.73,7,18.399,7z M10.768,7.999l5.055-5.055c0.235-0.234,0.237-0.613,0.002-0.849l-0.92-0.92
	c-0.234-0.234-0.614-0.233-0.85,0L9,6.231c-0.488,0.488-0.488,1.28,0,1.768C9.488,8.486,10.279,8.487,10.768,7.999z M3.823,17.271
	C3.92,17.672,4.338,18,4.75,18h10.5c0.412,0,0.83-0.328,0.927-0.729L17.7,11H2.3L3.823,17.271z
  `;

export const removeGroceryCart = `
M22.73 22.73L2.77 2.77 2 2l-.73-.73L0 2.54l4.39 4.39 2.21 4.66-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h7.46l1.38 1.38c-.5.36-.83.95-.83 1.62 0 1.1.89 2 1.99 2 .67 0 1.26-.33 1.62-.84L21.46 24l1.27-1.27zM7.42 15c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h2.36l2 2H7.42zm8.13-2c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H6.54l9.01 9zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2z
`;
