import styled from "styled-components";
import { Link } from "gatsby";

type PProps = {
  color?: string;
  backgroundColor?: string;
};

export const PrettyLink = styled("a")<PProps>`
  ${p => {
    const color = p.color || "#70a3b7";
    // const backgroundColor = p.backgroundColor || "#fff";
    return `
    transition: 0.3s text-decoration ease-in-out, 0.3s color ease-in-out;
  text-decoration: underline ${color} !important;

  &:hover {
    color: ${color};
    border-color: "transparent";
    transition: 0.1s text-decoration ease-in-out, 0.1s color ease-in-out;
    text-decoration: none !important;
  }
  `;
  }}
`;

export const PrettyMyLink = PrettyLink.withComponent(Link);
