import styled, { css } from "styled-components";
import { theme } from "./theme";

export const baseButton = css`
  outline: none;
  padding: 8px 1.6em;
  text-align: center;
  text-decoration: none;
  border: none;
  display: inline-block;
  letter-spacing: 0.026em;
  cursor: pointer;
  font-size: 0.875rem;
  border-radius: 3px;
`;

const reverseOutlineButtonButtonStyleMap = {
  default: css`
    background-color: #0d0d0d;
    border: solid 1px #0d0d0d;
    color: #fff;
    &:hover,
    &:disabled {
      color: #fff;
      background-color: #333;
      border-color: #333;
    }
  `,
  offWhite: css`
    background-color: #0d0d0d;
    border: solid 1px #0d0d0d;
    color: #fff;
    &:hover,
    &:disabled {
      color: #fff;
      background-color: #fafafa;
      border-color: #333;
    }
  `,
  red: css`
    background-color: #972409;
    border: solid 1px #972409;
    color: #fff;
    &:hover,
    &:disabled {
      color: #fff;
      background-color: #d13c1e;
      border-color: #d13c1e;
    }
  `,
};

export const ReverseOutlineButton = styled("button")<{
  variant?: keyof typeof reverseOutlineButtonButtonStyleMap;
}>`
  ${baseButton};
  font-family: ${theme.fonts.ui};
  ${p => reverseOutlineButtonButtonStyleMap[p.variant || "default"]}
`;

const blackOutlineButtonStyle = css`
  background-color: Transparent;
  color: #444;
  border: solid 1px #444;
  &:hover {
    background-color: #444;
    color: #fff;
  }
`;

const redOutlineButtonStyle = css`
  background-color: Transparent;
  color: #531410;
  border: solid 1px #531410;
  &:hover {
    color: #ffffff;
    background-color: #531410;
  }
`;

const outlineButtonStyleMap = {
  black: blackOutlineButtonStyle,
  red: redOutlineButtonStyle,
  gray: css`
    text-transform: none;
    background-color: Transparent;
    color: #999999;
    border: solid 1px #999999;
    &:hover {
      color: #ffffff;
      background-color: #999999;
    }
    & svg {
      fill: #999999;
    }
    &:hover svg {
      fill: #ffffff;
    }
  `,
  gold: css`
    background-color: Transparent;
    color: #444;
    border: solid 1px #c5bfa9;
    font-size: 1rem;
    &:hover {
      background-color: #c5bfa9;
    }
  `,
};

type OBProps = { color?: keyof typeof outlineButtonStyleMap };

export const OutlineButton = styled("button")<OBProps>`
  ${baseButton};
  font-family: ${theme.fonts.ui};
  text-transform: uppercase;
  ${p => outlineButtonStyleMap[p.color || "red"]};
`;
